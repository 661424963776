<template>
    <aside aria-live="assertive" class="sr-only">
        <div ref="currentBillingPeriod" v-text="activeBilling + ' billing selected'"></div>
    </aside>
</template>

<script>
// TODO: This is going to be used as a global "screen reader" state announcer
export default {
    data() {
        return {
        };
    },
    components: {
    },
    mounted() {
    },
    props: {
    },
    methods: {
    },
    created() {
    },
    computed: {
        activeBilling() {
            return this.$store.getters.billingPeriod;
        }
    },
    watch: {
        // TODO: Watch the users "cart" object. When something changes, ie: count, added, removed. We should auto update the live area with "<item name> added" or "<item name> removed" or "<item name> count updated"
    }
};
</script>

<style lang="postcss" scoped>
</style>
