<template>
    <div class="field-wrapper" :class="showError() ? 'field-wrapper--error' : ''">
        <label :for="inputId" :id="`label-${inputId}`" class="field-label-v2">
            {{ fieldLabel }}<span v-if="required" class="">&nbsp;*</span>
        </label>
        <input
            :type="fieldType"
            name=""
            class="field-input-v2"
            :class="showError() ? 'mb-1' : ''"
            :id="inputId"
            :value="localValue"
            :required="required"
            :readonly="readonly"
            :disabled="disabled || readonly"
            @blur="onBlur"
            @focus="onFocus"
            @input="updateInput($event.target.value)"
            :aria-labelledby="labelledByIds"
        />
        <div class="text-gray-2 text-xs" v-if="!showError() && $slots.helper" :for="inputId" :id="`helper-text-${inputId}`">
            <slot name="helper"></slot>
        </div>
        <div class="text-red-default text-xs" v-if="showError() && $slots.error" aria-live="polite">
            <slot name="error"></slot>
        </div>
    </div>
</template>

<script>
import { AsYouType } from 'libphonenumber-js/min';

export default {
    components: {
    },
    data() {
        return {
            isFocused: false,
            localValue: '',
            dirty: false,
        };
    },
    mounted() {
        this.asYouType = new AsYouType(this.countryCode || 'US');
    },
    props: {
        fieldLabel: String,
        fieldType: String,
        required: Boolean,
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        valid: Boolean,
        value: String,
    },
    methods: {
        onBlur() {
            this.isFocused = false;

            if (this.localValue.length > 0) {
                this.dirty = true;
            }
        },
        onFocus() {
            this.isFocused = true;
        },
        updateInput(value) {
            this.asYouType.reset();

            const displayFormat = this.asYouType.input(value);

            this.localValue = displayFormat;
            this.$emit('update:modelValue', this.localValue);
        },
        showError() {
            if (this.localValue.length <= 0 && !this.dirty) {
                return false;
            }
            if (!this.dirty) {
                return false;
            }
            return !this.valid;
        }
    },
    created() {
        this.localValue = this.value || '';
    },
    computed: {
        labelledByIds() {
            let ids = `label-${this.inputId}`;
            if (this.$slots.helper) {
                ids += ` helper-text-${this.inputId}`
            }
            return ids;
        },
        inputId() {
            const uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
            return uuid;
        },

        countryCode() {
            return this.$store.state.userInfo.country;
        },
    },
    watch: {
        localValue(newVal, oldVal) {
            this.localValue = newVal;
        },
        value(newVal, oldVal) {
            this.localValue = newVal;
        }
    }
};
</script>

<style lang="postcss" scoped>
</style>
