<template>
    <div class="spinner-wrapper">
        <KeapLogoSmall class="spinner-logo"/>
        <div class="spinner"></div>
    </div>
</template>

<script>
import KeapLogoSmall from '@/../svg/logo-small.svg?component';

export default {
    components: {
        KeapLogoSmall
    }
};
</script>

<style lang="postcss" scoped>
.spinner-wrapper {
    @apply w-[100px] h-[100px] flex justify-center items-center;
    clip-path: circle();
}

.spinner-logo {
    @apply absolute w-[30px] fill-green-default z-20;
}

.spinner {
    @apply border-blue-default inline-block rounded-[50%] w-full h-full border-t-[3px] border-b-[3px] border-r-[3px] border-l-[3px] border-l-transparent animate-spin;
}
</style>
