<template>
    <div class="field-wrapper" :class="showError() ? 'field-wrapper--error' : ''">
        <label :for="inputId" class="field-label-v2" :id="`label-${inputId}`" :class="{'hidden sr-only': hideLabel}">
            {{ fieldLabel }}<span v-if="required" class="">&nbsp;*</span>
        </label>
        <div class="flex items-center" :class="showError() || $slots.helper ? 'mb-2' : ''">
            <ChevronDown class="absolute right-2 pointer-events-none field-select-arrow" />
            <select
                name=""
                class="field-select"
                :id="inputId"
                :value="localValue"
                :required="required"
                ref="select"
                @blur="onBlur"
                @focus="onFocus"
                @change="updateInput($event.target.value)"
                :aria-labelledby="labelledByIds"
                :disabled="disabled"
            >
            <!-- TODO: Get pre selected default value working -->
                <!-- <option v-if="hasDefault" v-bind:selected="value == ''" v-text="defaultText"></option> -->
                <option v-for="option in options" v-bind:value="option.value" v-bind:key="option.value">{{ option.label }}</option>
            </select>
        </div>
        <div class="text-gray-2 text-xs" v-if="!showError() && $slots.helper" :for="inputId" :id="`helper-text-${inputId}`">
            <slot name="helper"></slot>
        </div>
        <div class="text-red-default text-xs" v-if="showError() && $slots.error" aria-live="polite">
            <slot name="error"></slot>
        </div>
    </div>
</template>

<script>
import ChevronDown from '@/../svg/chevron-down.svg?component';
export default {
    data() {
        return {
            isFocused: false,
            localValue: '',
            dirty: false,
        };
    },
    components: {
        ChevronDown
    },
    mounted() {
    },
    props: {
        fieldLabel: String,
        fieldType: String,
        required: Boolean,
        valid: Boolean,
        value: String,
        hasDefault: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        defaultText: {
            type: String,
            default: 'Select option'
        },
        options: {
            type: Array,
            default: [
                {
                    'value': '',
                    'selected': true,
                    'label': 'Select Option'
                }
            ]
        },
        hideLabel: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        onBlur() {
            this.isFocused = false;
            if (this.localValue.length > 0) {
                this.dirty = true;
            }
        },
        onFocus() {
            this.isFocused = true;
        },
        updateInput(value) {
            this.localValue = value;
            this.$emit('update:modelValue', this.localValue);
        },
        showError() {
            if (this.localValue.length <= 0 && !this.dirty) {
                return false;
            }
            if (!this.dirty) {
                return false;
            }
            return !this.valid;
        }
    },
    created() {
        this.localValue = this.value || '';
    },
    computed: {
        labelledByIds() {
            let ids = `label-${this.inputId}`;
            if (this.$slots.helper) {
                ids += ` helper-text-${this.inputId}`
            }
            return ids;
        },
        inputId() {
            const uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
            return uuid;
        }
    },
    watch: {
        localValue(newVal, oldVal) {
            this.localValue = newVal;
        },
        value(newVal, oldVal) {
            this.localValue = newVal;
        }
    }
};
</script>

<style lang="postcss" scoped>
</style>
